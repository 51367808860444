import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ opacity: 1 }), animate(230)]),
      transition('* => void', [animate(230, style({ opacity: 0 }))]),
    ]),
  ],
  standalone: true,
})
export class LoadingComponent {
  @Input() type: 'determinate' | 'indeterminate' = 'indeterminate';

  @HostBinding('@flyInOut') get animation(): boolean {
    return true;
  }
}
